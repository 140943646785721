<template>
  <v-row
    class="drop-zone justify-content-center align-center"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="getFiles"
    @change="getSelectedFiles"
    :class="{ 'active-dropzone': active }"
  >
    <p>Drag and drop video file</p>
    <p>or</p>
    <label for="elevatorPitchFile">Upload video file</label>
    <input
      type="file"
      id="elevatorPitchFile"
      class="elevatorPitchFile"
      accept="video/*"
      @click="$event.target.value = ''"
    />
    <p>Video file: {{ filename }}</p>
  </v-row>

  <LoadingDialog
    :modelValue="openLoadingDialog"
    message="Saving Elevator Pitch..."
  />

  <ErrorDialog
    v-model="openErrorDialog"
    :title="errorTitle"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />

  <SuccessDialog
    v-model="openSuccessDialog"
    title="Uploaded and saved!"
    message="Elevator pitch has been successfully uploaded and saved."
    @close-dialog="openSuccessDialog = false"
  />
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { usePitchStore } from "@/store/pitch";
import {
  ref as storageRef,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase/init";
import { doc, setDoc } from "firebase/firestore";
import LoadingDialog from "../../Dialogs/LoadingDialog.vue";
import ErrorDialog from "../../Dialogs/ErrorDialog.vue";
import SuccessDialog from "../../Dialogs/SuccessDialog.vue";

const emit = defineEmits(["disableNextButton"]);

const userStore = useUserStore();
const pitchStore = usePitchStore();

const { setElevatorFile } = pitchStore;

const { currentUser } = storeToRefs(userStore);
const { pitch } = storeToRefs(pitchStore);

let openLoadingDialog = ref(false);
let openSuccessDialog = ref(false);
let openErrorDialog = ref(false);
let errorTitle = ref("");
let errorMsg = ref("");
const active = ref(false);
const file = ref("");
const newFile = ref("");
const filename = ref("");
const videoElement = ref(document.createElement("video"));
const maxDuration = 30; //30 seconds

const storageFolder = "elevatorpitches/" + currentUser.value.companyuid +"/";

const toggleActive = () => {
  active.value = !active.value;
};
const getFiles = (event) => {
  newFile.value = event.dataTransfer.files[0];
  validateFile();
  toggleActive();
};
const getSelectedFiles = (event) => {
  newFile.value = event.target.files[0];
  validateFile();
};

const validateFile = () => {
  if (newFile.value.type !== "video/mp4") {
    errorTitle.value = "Wrong type of file!";
    errorMsg.value =
      "Oops! It looks like you uploaded the wrong type of file. Currently, Elevator pitch only accepts video files.";
    openErrorDialog.value = true;
  } else {
    videoElement.value.src = URL.createObjectURL(newFile.value);
  }
};

videoElement.value.addEventListener("loadedmetadata", async () => {
  var duration = videoElement.value.duration;

  if (duration > maxDuration) {
    errorTitle.value = "Video too long!";
    errorMsg.value =
      "Oops! It looks like your Elevator Pitch video is longer than 30 seconds. For better viewership, please upload a video that's 30 seconds or shorter.";
    openErrorDialog.value = true;
  } else if (duration <= maxDuration) {
    file.value = newFile.value;

    await getFirebaseData();
  }
});

const getFirebaseData = async () => {
  openLoadingDialog.value = true;

  try {
    const fbStorage = getStorage();
    const fbStorageRef = storageRef(fbStorage, storageFolder + file.value.name);
    const snapshot = await uploadBytes(fbStorageRef, file.value);
    const downloadURL = await getDownloadURL(storageRef(fbStorageRef));

    console.log(snapshot);

    await setDoc(doc(db, "elevatorpitches", currentUser.value.uid), {
      downloadURL: downloadURL,
      filename: file.value.name,
    });
    setElevatorFile({
      filename: file.value.name,
      url: downloadURL,
    });

    openSuccessDialog.value = true;
  } catch (e) {
    console.log(e);
  }

  openLoadingDialog.value = false;
};
const closeErrorDialog = () => {
  newFile.value = videoElement.value.removeAttribute("src");
  openErrorDialog.value = false;
};

//life cycle hooks
watchEffect(async () => {
  filename.value =
    file.value !== ""
      ? file.value.name
        ? file.value.name
        : file.value.filename !== ""
        ? file.value.filename
        : "No Video file uploaded"
      : "No video file uploaded";

  emit("disableNextButton", pitch.value.elevator.url === "");
});

onMounted(() => {
  file.value = pitch.value.elevator;
});
</script>

<style scoped>
.drop-zone {
  margin-top: 3%;
  height: 43vh;
  width: 100%;
  flex-direction: column;
  border: 2px dashed #4c6fbf;

  label {
    padding: 1% 2%;
    background-color: #4c6fbf;
    color: white !important;
    margin: 2%;
  }

  input {
    display: none;
  }
}
.active-dropzone {
  color: white;
  border-color: white;
  background-color: #4c6fbf;

  label {
    background-color: white;
    color: #4c6fbf !important;
    margin: 2%;
  }
}
</style>

<template>
  <v-col cols="12" class="align-center justify-content-center">
    <v-form
      fast-fail
      @submit.prevent
      class="pa-3 bg-background align-center justify-content-center"
    >
      <v-text-field label="Subject" :Required="true" v-model="title" />
      <VuetifyTiptap
        v-model="body"
        class="editor"
        :extensions="extensions"
        :max-height="200"
      />
      <v-btn
        class="bg-primary mt-2"
        :disabled="disablePublish"
        :loading="isSaveButtonLoading"
        @click="isExisting ? editNewsletter() : addToNewsletterQueue()"
        >{{ isExisting ? "Save Changes" : "Publish" }}</v-btn
      >
      <v-btn
        v-if="isExisting && !isMobile.newsletter"
        variant="text"
        class="mt-2"
        @click="clearSelectedNewsletter"
        >Clear and cancel edit</v-btn
      >
      <v-btn
        v-if="isMobile.newsletter"
        :loading="isSaveButtonLoading"
        variant="text"
        class="mt-2"
        @click="clearSelectedNewsletter"
        >Cancel</v-btn
      >
    </v-form>
  </v-col>
</template>

<script setup>
import { ref, defineEmits, watchEffect, computed } from "vue";
import { useCommonStore } from "@/store/common";
import { useNewsletterStore } from "@/store/newsletter";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";
import { db } from "@/firebase/init";
import { addDoc, collection,  doc, updateDoc } from "firebase/firestore";
import {
  VuetifyTiptap,
  BaseKit,
  Bold,
  Color,
  Heading,
  Highlight,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  Strike,
  Table,
  TextAlign,
  Underline,
  Video,
} from "vuetify-pro-tiptap";
import "vuetify-pro-tiptap/style.css";

const emit = defineEmits(["close-dialog"]);

const commonStore = useCommonStore();
const newsletterStore = useNewsletterStore();
const userStore = useUserStore();

const { isMobile } = storeToRefs(commonStore);
const { selectedNewsletter, newslettersList, isEmpty } = storeToRefs(newsletterStore);
const { addNewsletter, updateNewsletter, clearNewsletter } = newsletterStore;
const { currentUser } = storeToRefs(userStore);

let id = ref("");
let title = ref("");
let body = ref("");
let disablePublish = ref(false);
let isSaveButtonLoading = ref(false);
const extensions = [
  BaseKit.configure({
    placeholder: {
      placeholder: "Enter the body of your newsletter...",
    },
  }),
  Bold,
  Italic,
  Underline,
  Strike,
  Color,
  Highlight,
  Heading,
  HorizontalRule,
  Link,
  Image,
  Video,
  Table,
  TextAlign,
  History,
];

const addToNewsletterQueue = async () => {
  isSaveButtonLoading.value = true;

  let newNewsletter = {
    date: new Date().toISOString(),
    title: title.value,
    body: body.value,
    isSent: false,
    companyID: currentUser.value.companyuid,
  };

  const newsletterRef = await addDoc(
    collection(db, "newsletters"),
    newNewsletter
  );

  console.log("Document written with ID: ", newsletterRef.id);

  newNewsletter.id = newsletterRef.id;

  addNewsletter(newNewsletter);

  title.value = "";
  body.value = "";

  clearNewsletter();

  if (isMobile.value.newsletter) {
    emit("close-dialog");
  }
  isSaveButtonLoading.value = false;
};

const editNewsletter =  async () => {
  isSaveButtonLoading.value = true;

  const index = getNewsletterIndex();

  try{
    
  const newsLetterRef = doc(db, "newsletters", newslettersList.value[index].id);

  await updateDoc(newsLetterRef, {
    title: title.value,
    body: body.value
  });

  updateNewsletter(index, { 
    title: title.value, 
    body: body.value,
    date: new Date().toISOString(),
    isSent: false,
    companyID: currentUser.value.companyuid,
    id:  newslettersList.value[index].id
  });

  isSaveButtonLoading.value = false;
  clearSelectedNewsletter();
 }
 catch(e)
 {
  console.log(e);

 }

};

const clearSelectedNewsletter = () => {
  id.value = null;
  title.value = null;
  body.value = null;
  emit("close-dialog");
};

const getNewsletterIndex = () =>
  newslettersList.value.findIndex((x) => x.id === id.value);

const isExisting = computed(() => getNewsletterIndex() >= 0);

watchEffect(() => {
  if (isEmpty.value && (title.value === "" || body.value === "")) {
    disablePublish.value = true;
  } else if (isEmpty.value || (title.value === "" && body.value === "")) {
    disablePublish.value = false;
  }

  if (selectedNewsletter.value) {
    id.value = selectedNewsletter.value?.id;
    title.value = selectedNewsletter.value?.title;
    body.value = selectedNewsletter.value?.body;
    clearNewsletter();
  }
});
</script>

<style scoped>
.v-row {
  width: 100%;
  height: 80vh;
  flex-direction: column;
}
.v-form {
  width: 100%;
  height: fit-content;
  flex-direction: column;
}
.editor {
  width: 100%;
}
.v-btn {
  width: 50%;
}
</style>

<template>
  <v-text-field
    v-model="formattedValue"
    label="Amount Raising (Peso)*"
    :rules="[rules.required]"
    ref="inputRef"
    :readonly="props.isReadonly"
    :hint="
      props.isReadonly
        ? 'Amount Raising is disabled if you are Seeking Feedback in your Fundraising Round'
        : ''
    "
    :persistent-hint="true"
  />
</template>

<script setup>
import { watch, defineProps } from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import { useCurrencyInput } from "vue-currency-input";

const props = defineProps(["modelValue", "isReadonly"]);

const commonStore = useCommonStore();

const { rules } = storeToRefs(commonStore);

const { inputRef, formattedValue, setValue } = useCurrencyInput({
  currency: "PHP",
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  precision: 2,
});

watch(
  () => props.modelValue,
  (value) => {
    setValue(value);
  }
);
</script>

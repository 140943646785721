<template>
  <v-form
    fast-fail
    @submit.prevent
    class="bg-background align-center justify-content-center"
  >
    <v-row class="justify-content-center">
      <v-col cols="10" class="justify-content-center logo"
        ><v-img
          :src="require('../../assets/pitchly-logo-transparent.png')"
          alt="App Logo"
          max-width="50%"
          contain
          @click="router.push('/')"
      /></v-col>
      <v-col cols="10">
        <v-text-field
          v-model="email"
          label="Email Address*"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="10">
        <v-text-field
          v-model="password"
          label="Password*"
          :rules="[rules.required]"
          :append-inner-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="passwordVisible ? 'text' : 'password'"
          @click:append-inner="passwordVisible = !passwordVisible"
        />
      </v-col>
      <v-col cols="10"
        ><v-btn
          class="bg-primary"
          @click="login"
          :disabled="isLoginDisabled"
          :loading="isLoggingIn"
          >Log-In</v-btn
        ></v-col
      >
      <v-col cols="10" class="justify-content-center">
        <v-btn
          @click="openForgetPasswordDialog = true"
          class="text-secondary-blue no-uppercase"
          variant="text"
          >Forgot password?</v-btn
        >
      </v-col>
      <v-col cols="10" class="justify-content-center">
        <v-btn
          @click="router.push('/signup')"
          class="text-secondary-blue no-uppercase"
          variant="text"
          >New user?</v-btn
        >
      </v-col>
    </v-row>
  </v-form>

  <ErrorDialog
    v-model="openErrorDialog"
    title="Log-in failed!"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />
  <ForgetPassword
    v-model="openForgetPasswordDialog"
    @close-dialog="closeForgetPasswordDialog"
  />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { auth, db } from "@/firebase/init";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import { useCompanyStore } from "@/store/company";
import { usePitchStore } from "@/store/pitch";
import { storeToRefs } from "pinia";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import ErrorDialog from "../Dialogs/ErrorDialog.vue";
import ForgetPassword from "../Dialogs/ForgetPassword.vue";
import { useNewsletterStore } from "@/store/newsletter";

const router = useRouter();
const commonStore = useCommonStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();
const pitchStore = usePitchStore();
const newslettersStore = useNewsletterStore();

const email = ref("");
const password = ref("");
const passwordVisible = ref(false);
let isLoginDisabled = ref(true);
let isLoggingIn = ref(false);
let openErrorDialog = ref(false);
let openForgetPasswordDialog = ref(false);
let errorMsg = ref("");

const { setUserDetails } = userStore;
const { setCompanyDetails, setInvestmentDetails } = companyStore;
const { setPitchDeckFile, setElevatorFile, setOnePagerFile } = pitchStore;
const { addNewsletter } = newslettersStore;

const { rules } = storeToRefs(commonStore);

const login = async () => {
  isLoggingIn.value = true;
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value
    );
    const docRef = doc(db, "user", userCredential.user.uid);
    const docSnap = await getDoc(docRef);
    const user = docSnap.data();

    setUserDetails({
      firstName: user.firstName,
      lastName: user.lastName,
      contactNumber: user.mobileNumber,
      contactEmail: user.email,
      uid: userCredential.user.uid,
      companyuid: user.company,
    });
    console.log(user);

    const docRefCompany = doc(db, "company", user.company);
    const docSnapCompany = await getDoc(docRefCompany);
    const company = docSnapCompany.data();

    console.log(company);

    setCompanyDetails({
      companyName: company.companyName,
      description: company.companyDescription,
      stage: company.stage,
      tags: company.tags,
    });

    const refNewsletters = collection(db, "newsletters");
    const queryNewsletters = query(
      refNewsletters,
      where("companyID", "==", user.company)
    );

    const querySnapshotNewsletters = await getDocs(queryNewsletters);
    querySnapshotNewsletters.forEach((doc) => {
      const newsletterData = doc.data();
      newsletterData.id = doc.id;
      console.log(doc.id, " => ", newsletterData);
      addNewsletter(newsletterData);
    });

    setInvestmentDetails({
      investmentTerm: company.terms,
      offer: company.offer,
      amountRaising: company.amountRaising,
    });
    const docRefPitchDeck = doc(db, "pitchdecks", userCredential.user.uid);
    const docSnapPitchdeck = await getDoc(docRefPitchDeck);

    const docRefEle = doc(db, "elevatorpitches", userCredential.user.uid);
    const docSnapEle = await getDoc(docRefEle);

    const docRefOne = doc(db, "onepagers", userCredential.user.uid);
    const docSnapOne = await getDoc(docRefOne);

    if (docSnapPitchdeck.exists()) {
      setPitchDeckFile({
        filename: docSnapPitchdeck.data().filename,
        url: docSnapPitchdeck.data().downloadURL,
      });
      console.log(docSnapPitchdeck.data());
    }
    if (docSnapEle.exists()) {
      setElevatorFile({
        filename: docSnapEle.data().filename,
        url: docSnapEle.data().downloadURL,
      });
      console.log(docSnapEle.data());
    }
    if (docSnapOne.exists()) {
      setOnePagerFile({
        filename: docSnapOne.data().filename,
        url: docSnapOne.data().downloadURL,
      });
      console.log(docSnapOne.data());
    }

    console.log("router pushing");
    router.push("/pitch");
  } catch (e) {
    isLoggingIn.value = false;
    errorMsg.value = e.message;
    openErrorDialog.value = true;
    console.log(e);
  }
};
const closeErrorDialog = () => {
  openErrorDialog.value = false;
};
const closeForgetPasswordDialog = () => {
  openForgetPasswordDialog.value = false;
};

watchEffect(() => {
  isLoginDisabled.value = email.value === "" || password.value === "";
});
</script>

<style scoped>
.v-row {
  width: 100%;
}
.v-col {
  padding: 0;
}
.v-form {
  height: 80%;
  width: 80%;
  padding: 2%;
}
.v-btn {
  width: 100%;
}
.logo {
  margin-bottom: 5%;
}
</style>

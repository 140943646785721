<template>
  <v-row
    class="main container-with-nav justify-content-end align-space-between"
  >
    <v-row class="content justify-content-center align-space-around">
      <SkeletonLoader v-if="loading" type="card@2" />
      <PDFViewer v-if="currentPitch === 'pitchDeck' && !loading" />
      <VideoViewer v-if="currentPitch === 'elevator' && !loading" />
      <PDFViewer v-if="currentPitch === 'onePager' && !loading" />
    </v-row>

    <v-list class="bg-background" v-if="!isMobile.default">
      <v-list-item
        v-for="pitch in pitches"
        :key="pitch.key"
        :title="pitch.label"
        :value="pitch.label"
        :class="currentPitch === pitch.key ? 'current' : ''"
        @click="setCurrentPitch(pitch.key)"
        :disabled="pitch.disabled"
      />
    </v-list>
    <v-row
      class="footer bg-secondary-blue-darken align-center justify-content-space-around"
    >
      <p>
        {{ company.companyName }} by {{ currentUser.firstName }}
        {{ currentUser.lastName }}
      </p>
      <p>
        Amount Raising:
        {{
          new Intl.NumberFormat("en-PH", {
            style: "currency",
            currency: "PHP",
          }).format(company.amountRaising)
        }}
      </p>
      <p>Fundraising Round: {{ company.investmentTerm }}</p>
    </v-row>
  </v-row>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCommonStore } from "@/store/common";
import { usePitchStore } from "@/store/pitch";
import { useUserStore } from "@/store/user";
import { useCompanyStore } from "@/store/company";
import { storeToRefs } from "pinia";
import { db,auth } from "@/firebase/init";
import { doc, getDoc } from "firebase/firestore";
import PDFViewer from "@/components/Viewers/PDFViewer.vue";
import VideoViewer from "@/components/Viewers/VideoViewer.vue";
import SkeletonLoader from "@/components/SkeletonLoder.vue";

const commonStore = useCommonStore();
const pitchStore = usePitchStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();

const { isMobile } = storeToRefs(commonStore);
const { currentPitch, pitches } = storeToRefs(pitchStore);
const { currentUser } = storeToRefs(userStore);
const { company } = storeToRefs(companyStore);

const { setPitch } = pitchStore;
const { setCurrentPitch } = pitchStore;

let loading = ref(true);

onMounted(async () => {
  
  if(auth.currentUser){
  setCurrentPitch("pitchDeck");

  const docRefPitchDeck = doc(db, "pitchdecks", currentUser.value.uid);
  const docSnapPitchdeck = await getDoc(docRefPitchDeck);

  const docRefEle = doc(db, "elevatorpitches", currentUser.value.uid);
  const docSnapEle = await getDoc(docRefEle);

  const docRefOne = doc(db, "onepagers", currentUser.value.uid);
  const docSnapOne = await getDoc(docRefOne);

  setPitch({
    onePager: {
      filename: docSnapOne.data().filename,
      url: docSnapOne.data().downloadURL,
    },
    elevator: {
      filename: docSnapEle.data().filename,
      url: docSnapEle.data().downloadURL,
    },
    pitchDeck: {
      filename: docSnapPitchdeck.data().filename,
      url: docSnapPitchdeck.data().downloadURL,
    },
  });

  
}
loading.value = false;
});
</script>

<style scoped>
.main {
  width: 100vw;
  padding: 0;
  .v-list {
    width: 20vw;
    border-left: 2px solid#949291;
    .current {
      background-color: #ff9933;
      color: white;
    }
  }
  overflow: hidden;
}
.content {
  width: 80vw;
  height: 90%;
  max-height: 95%;
  overflow-y: auto;
  padding: 0;
  margin-top: 1%;
}
.footer {
  width: 100vw;
  min-height: 5%;
  max-height: 15%;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SignUpView from "@/views/SignUpView.vue";
import LogInView from "@/views/LogInView.vue";
import PitchView from "@/views/PitchView.vue";
import AnalyticsView from "@/views/AnalyticsView.vue";
import NewsletterView from "@/views/NewsletterView.vue";
import AccountView from "@/views/AccountView.vue";
import PreviewView from "@/views/PreviewView.vue";
import OnePagerTemplate from "@/views/OnePagerTemplateView.vue";
import InvestorView from "@/views/InvestorView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUpView,
  },
  {
    path: "/login",
    name: "login",
    component: LogInView,
  },
  {
    path: "/pitch",
    name: "pitch",
    component: PitchView,
  },
  {
    path: "/preview",
    name: "preview",
    component: PreviewView,
  },
  {
    path: "/analytics",
    name: "analytics",
    component: AnalyticsView,
  },
  {
    path: "/newsletter",
    name: "newsletter",
    component: NewsletterView,
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
  },
  {
    path: "/download/one-pager",
    name: "onePagerTemplate",
    component: OnePagerTemplate,
  },
  {
    path: "/investor/:userId",
    name: "investorView",
    component: InvestorView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

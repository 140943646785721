<template>
  <v-col cols="12">
    <v-card class="bg-background">
      <v-row class="card align-center">
        <v-row class="content">
          <v-card-title class="heading body-size text-primary">{{
            currentAide.title
          }}</v-card-title>

          <v-card-text v-if="currentAide.content !== undefined">
            {{ currentAide.content }}
          </v-card-text>
          <currentAide.component v-else />
        </v-row>
      </v-row>

      <template v-slot:actions v-if="isMobile.pitch">
        <v-btn class="ms-auto" text="Got it" @click="emit('close-dialog')" />
      </template>
    </v-card>
  </v-col>
</template>

<script setup>
import { defineEmits } from "vue";
import { useCommonStore } from "@/store/common";
import { useAideStore } from "@/store/aide";
import { storeToRefs } from "pinia";

const emit = defineEmits(["close-dialog"]);
const commonStore = useCommonStore();
const aideStore = useAideStore();

const { isMobile } = storeToRefs(commonStore);
const { currentAide } = storeToRefs(aideStore);
</script>

<style scoped>
.v-col {
  height: 90%;
}
.v-card,
.card {
  overflow-y: auto;
  height: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.content {
  height: fit-content;
}
.v-card-title {
  width: 100%;
  padding: 0;
  margin-bottom: 2%;
  text-wrap: wrap;
  text-align: center;
}
.v-card-text {
  text-align: center;
}
</style>

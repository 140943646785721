<template>
  <NavBar editorView="true" />
  <TwoColLayout
    :leftCols="isMobile.newsletter ? 0 : 7"
    :rightCols="isMobile.newsletter ? 12 : 5"
    :leftLayout="NewsletterForm"
    :rightLayout="NewsletterList"
    :withNav="true"
  />
</template>

<script setup>
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import NavBar from "@/components/NavBar.vue";
import TwoColLayout from "@/components/TwoColLayout.vue";
import NewsletterForm from "@/components/Forms/NewsletterForm.vue";
import NewsletterList from "@/components/NewsletterList.vue";

const commonStore = useCommonStore();

const { isMobile } = storeToRefs(commonStore);
</script>

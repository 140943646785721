<template class="bg-secondary-background">
  <TwoColLayout
    :leftCols="isMobile.default ? 12 : 8"
    :rightCols="isMobile.default ? 0 : 4"
    photo="home-intro-vector.png"
    :leftLayout="LogInForm"
    class="bg-secondary-background"
  />
</template>

<script setup>
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import TwoColLayout from "@/components/TwoColLayout.vue";
import LogInForm from "@/components/Forms/LogInForm.vue";

const commonStore = useCommonStore();

const { isMobile } = storeToRefs(commonStore);
</script>

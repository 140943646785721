<template>
  <v-dialog
    v-model="isActive"
    @click:outside="closeDialog"
    :class="isMobile.default ? 'mobile-width' : 'normal-width'"
  >
    <v-card>
      <v-card-title class="text-primary title body-size justify-content-center">
        Forgot your password?
      </v-card-title>
      <v-card-text>
        <v-combobox
          v-model="email"
          :hideSelected="true"
          label="Your email"
          :multiple="false"
          :chips="false"
          :clearable="true"
          :rules="[rules.required, rules.email]"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text="Cancel" @click="closeDialog"></v-btn>

        <v-btn
          class="bg-primary primary-button"
          text="Verify"
          variant="flat"
          @click="sendEmail"
          :disabled="!emailPattern.test(email) || email === ''"
          :loading="isLoading"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <SuccessDialog
    v-model="openSuccessDialog"
    title="Email Sent!"
    message="Check your registered email."
    @close-dialog="closeSuccessDialog"
  />
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import { auth } from "@/firebase/init";
import { sendPasswordResetEmail } from "firebase/auth";
import SuccessDialog from "./SuccessDialog.vue";

const commonStore = useCommonStore();
const props = defineProps(["modelValue"]);
const emit = defineEmits(["close-dialog"]);

const { isMobile, rules, emailPattern } = storeToRefs(commonStore);

const isActive = ref(false);
const email = ref("");
let openSuccessDialog = ref(false);
let isLoading = ref(false);

const sendEmail = async () => {
  isLoading.value = true;
  openSuccessDialog.value = true;
  isLoading.value = false;
  try {
    await sendPasswordResetEmail(auth, email.value);

    closeDialog();
  } catch (e) {
    console.log(e);
  }
};
const closeDialog = () => {
  emit("close-dialog");
};
const closeSuccessDialog = () => {
  openSuccessDialog.value = false;
};

watchEffect(() => {
  isActive.value = props.modelValue;
});
</script>

<style scoped>
.v-dialog {
  max-height: 400px;
  min-height: 200px;
  flex-direction: column;
}
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 50%;
}
.v-card {
  height: 100%;
  padding: 3%;
}
.v-combobox {
  max-height: 50px;
}
</style>

<template>
  <v-dialog
    v-model="isActive"
    :class="isMobile.default ? 'mobile-width' : 'normal-width'"
    @click:outside="closeDialog"
  >
    <v-data-table
      :items="offers"
      hide-default-footer
      fixed-header
      disable-sort
    ></v-data-table>
  </v-dialog>
</template>

<script setup>
import { ref, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { useOnePagerStore } from "@/store/onePager";
import { storeToRefs } from "pinia";

const emit = defineEmits(["close-dialog"]);

const commonStore = useCommonStore();
const onePagerStore = useOnePagerStore();

const { isMobile } = storeToRefs(commonStore);
const { offers } = storeToRefs(onePagerStore);

let isActive = ref(true);

const closeDialog = () => {
  isActive.value = false;
  emit("close-dialog");
};

watchEffect(() => {
  if (!isMobile.value.default) {
    closeDialog();
  }
});
</script>

<style scoped>
.v-dialog {
  height: 80%;
  flex-direction: column;
  overflow-y: auto;
}
.v-data-table {
  overflow-y: auto;
  padding: 20px;
}
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 80%;
}
</style>

<template>
  <v-dialog
    v-model="isActive"
    @click:outside="closeDialog"
    :class="isMobile.newsletter ? 'mobile-width' : 'normal-width'"
  >
    <v-card>
      <v-card-title class="text-primary title body-size justify-content-center">
        Share Pitchly!
      </v-card-title>
      <v-card-text class="emails">
        <v-row>
          <p class="mb-3">
            Congratulations! You are ready to share your Pitchly link to
            potential investors.
          </p>
          <v-combobox
            v-model="emails"
            :hideSelected="true"
            :items="emails"
            label="Emails*"
            :multiple="true"
            :chips="true"
            :clearable="true"
            clear-icon="$clear"
            :rules="[rules.required, rules.email]"
          />
        </v-row>
        <v-row>
          <TextArea
            v-model="optionalmessage"
            label="Message (optional)"
            :isRequired="false"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="no-uppercase text-secondary-blue primary-button subtitle"
          @click="copy"
          :loading="copyDetails.isLoading"
        >
          <template v-slot:prepend>
            <v-icon color="secondary-blue" :icon="copyDetails.icon" />
          </template>
          {{ copyDetails.label }}
        </v-btn>

        <v-spacer />

        <v-btn text="Cancel" @click="closeDialog"></v-btn>

        <v-btn
          class="bg-primary primary-button"
          text="Share"
          variant="flat"
          @click="sharePitch"
          :disabled="
            emails.length < 1 ||
            emails.findIndex((email) => emailPattern.test(email)) < 0
          "
          :loading="isSharing"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <SuccessDialog
    v-model="openSuccessDialog"
    title="Pitch shared!"
    message="This pitch has been successfully shared through email."
    @close-dialog="closeSuccessDialog"
  />
  <ErrorDialog
    v-model="openErrorDialog"
    title="Error in Pitch sharing!"
    message="Please contact our support or try again later."
    @close-dialog="closeErrorDialog"
  />
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import { useCompanyStore } from "@/store/company";
import { useMailStore } from "@/store/mail";
import { addDoc, collection } from "firebase/firestore";
import { db } from "@/firebase/init";

import SuccessDialog from "./SuccessDialog.vue";
import ErrorDialog from "./ErrorDialog.vue";
import TextArea from ".././Forms/FormFields/TextArea.vue";

const commonStore = useCommonStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();
const mailStore = useMailStore();

const { isMobile, rules, emailPattern } = storeToRefs(commonStore);
const { currentUser } = storeToRefs(userStore);
const { company } = storeToRefs(companyStore);

const { generateShareEmailTemplate } = mailStore;

const props = defineProps(["modelValue"]);
const emit = defineEmits(["close-dialog"]);

const url = "https://pitchly.online/investor/" + currentUser.value.uid;
const isActive = ref(false);
const emails = ref([]);
const optionalmessage = ref("");
const copyDetails = ref({
  isLoading: false,
  label: "Copy Pitchly link to share",
  icon: "mdi-link",
});
let isSharing = ref(false);
let openSuccessDialog = ref(false);
let openErrorDialog = ref(false);

const sharePitch = async () => {
  isSharing.value = true;
  console.log(emails.value.length);

  for (let i = 0; i < emails.value.length; i++) {
    try {
      console.log(emails.value[i]);
      console.log(company.value);
      const email = generateShareEmailTemplate(
        currentUser.value,
        company.value,
        optionalmessage.value
      );
      await addDoc(collection(db, "mail"), {
        to: emails.value[i],
        message: {
          subject: email.subject,
          html: email.html,
        },
      });

      isActive.value = false;
      isSharing.value = false;
      openSuccessDialog.value = true;
      closeDialog();
    } catch (e) {
      openErrorDialog.value = true;
      console.log(e);
    }
  }
};
const closeDialog = () => {
  emit("close-dialog", false);
};
const copy = async () => {
  copyDetails.value.isLoading = true;

  await navigator.clipboard.writeText(url);

  copyDetails.value = {
    isLoading: false,
    label: "Pitchly link copied!",
    icon: "mdi-check-bold",
  };
};
const closeSuccessDialog = () => {
  openSuccessDialog.value = false;
};
const closeErrorDialog = () => {
  openErrorDialog.value = false;
};

watchEffect(() => {
  isActive.value = props.modelValue;
});
</script>

<style scoped>
.v-dialog {
  height: 80%;
  flex-direction: column;
}
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 50%;
}
.v-card {
  height: 100vh;
  padding: 3%;
}
</style>

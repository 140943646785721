<template>
  <v-dialog
    v-model="isActive"
    :class="isMobile.default ? 'mobile-width' : 'normal-width'"
    @click:outside="closeDialog"
  >
    <props.component @close-dialog="closeDialog" />
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";

const props = defineProps(["component"]);
const emit = defineEmits(["close-dialog"]);
const commonStore = useCommonStore();

const { isMobile } = storeToRefs(commonStore);

let isActive = ref(true);

const closeDialog = () => {
  isActive.value = false;
  emit("close-dialog");
};

watchEffect(() => {
  if (!isMobile.value.default) {
    closeDialog();
  }
});
</script>

<style scoped>
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 50%;
}
</style>

<template>
  <v-form fast-fail @submit.prevent class="bg-background">
    <v-select
      v-model="raising"
      :items="raisings"
      label="Fundraising Round*"
      :rules="[rules.required]"
    />
    <v-select
      v-model="offer"
      :items="offers"
      :item-props="
        (item) => ({
          title: item.name,
        })
      "
      label="Offer to Investor*"
    >
      <template v-slot:append>
        <v-btn
          icon="mdi-help-circle-outline"
          @click="openInformationDialog = true"
          variant="plain"
          class="ma-0 pa-0"
        ></v-btn>
      </template>
    </v-select>
    <CurrencyInput v-model="rawAmount" :isReadonly="disableAmount" />
  </v-form>

  <InformationDialog
    v-model="openInformationDialog"
    @close-dialog="openInformationDialog = false"
  />
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from "vue";
import { useCommonStore } from "@/store/common";
import { useCompanyStore } from "@/store/company";
import { useOnePagerStore } from "@/store/onePager";
import { storeToRefs } from "pinia";
import CurrencyInput from "../CurrencyInput.vue";
import InformationDialog from "@/components/Dialogs/InformationDialog.vue";

const emit = defineEmits(["disableNextButton"]);

const commonStore = useCommonStore();
const companyStore = useCompanyStore();
const onePagerStore = useOnePagerStore();

const { setInvestmentDetails } = companyStore;

const { rules } = storeToRefs(commonStore);
const { company } = storeToRefs(companyStore);
const { raisings, offers } = storeToRefs(onePagerStore);

let raising = ref(raisings.value[0]);
let offer = ref(offers.value[0]);
let rawAmount = ref(0);
let disableAmount = ref(true);
let openInformationDialog = ref(false);

onMounted(() => {
  raising.value = company.value.investmentTerm;
  offer.value = company.value.offer;
  rawAmount.value = company.value.amountRaising;
});

watchEffect(() => {
  if (raising.value === "Seeking Feedback") {
    rawAmount.value = 0.0;
    disableAmount.value = true;
  } else {
    disableAmount.value = false;
  }

  if (
    isNaN(rawAmount.value) ||
    rawAmount.value < 0 ||
    (rawAmount.value === 0 && raising.value !== "Seeking Feedback")
  ) {
    emit("disableNextButton", true);
  } else {
    emit("disableNextButton", false);
    setInvestmentDetails({
      investmentTerm: raising.value,
      offer: offer.value,
      amountRaising: rawAmount.value,
    });
  }
});
</script>

<style scoped>
.v-form {
  width: 100%;
  margin-top: 3%;
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";
import { addDoc, collection } from "firebase/firestore";
import { db } from "@/firebase/init";

//for refactoring - check if all email sending can be pooled into here. tried it and the email is always blank
export const useMailStore = defineStore(
  "MailStore",
  () => {
    const email = ref({
      to: "",
      message: {
        subject: "",
        html: "",
      },
    });

    const setEmail = (payload) => {
      const { to, subject, html } = payload;
      email.value.to = to;
      email.value.message.subject = subject;
      email.value.message.html = html;

      console.log("email");
      console.log(email);
    };

    const sendEmail = async () => {
      console.log("email");
      console.log(email);

      try {
        await addDoc(collection(db, "mail"), email.value);
        clearEmail();
      } catch (e) {
        console.log(e);
      }
    };

    const clearEmail = () => {
      email.value = {
        to: "",
        message: {
          subject: "",
          html: "",
        },
      };
    };

    const generateNewsletterEmailTemplate = (
      currentUser,
      company,
      newsletter
    ) => ({
      subject: newsletter.title,
      html: `<div style="display:flex; justify-content:center">
              <div style="border-style:solid; padding:20px; width: 600px;">
                <h1 style="margin:20px 0 5px 20px; text-align:center;"> ${newsletter.title} </h1>
                <h3 style="margin:5px 20px; text-align:center;"> ${company.companyName} </h3>
                <h5 style="margin:10px 20px; text-align:center;"> <i> written by: ${currentUser.firstName} ${currentUser.lastName} </i> </h5>
                <hr/>
                <span>${newsletter.body}</span>
                <hr style="color:#FF9933" />
                <i>Pitchly is an innovative online platform designed to help fundraising startups streamline
                  and enhance their pitch presentations, making them more appealing and accessible to
                  investors.
                  <br />
                  <br />
                  <span style="display:flex; align-item:center">
                    Follow Pitchly:
                    <a href="https://www.facebook.com/groups/8267625729933890" style="color:#FF9933">
                      Facebook
                    </a>
                  </span>    
                </i>
              </div>
             </div>`,
    });

    const generateShareEmailTemplate = (currentUser, company, message) => ({
      subject: `Pitchly shared with you: ${company.companyName}`,
      html: `<div style="display:flex; justify-content:center">
                <div style="border-style:solid; padding:20px; width: 600px;">
                  <h1 style="margin:20px 0 5px 20px;"> ${currentUser.firstName} ${currentUser.lastName} shared with you their startup pitch for ${company.companyName}. </h1>
                  <h3 style="margin:15px 20px;"> View their Pitchly <a href="https://pitchly.online/investor/${currentUser.uid}" style="color:#FF9933"> here </a> </h3>
                  <p style="margin:20px 0 5px 20px;">${message}</p>
                  <hr style="color:#FF9933" />
                  <i>Pitchly is an innovative online platform designed to help fundraising startups streamline
                    and enhance their pitch presentations, making them more appealing and accessible to
                    investors.
                    <br />
                    <br />
                    <span style="display:flex; align-item:center">
                      Follow Pitchly:
                      <a href="https://www.facebook.com/groups/8267625729933890" style="color:#FF9933">
                        Facebook
                      </a>
                    </span>    
                  </i>
                </div>
              </div>`,
    });

    return {
      email,
      setEmail,
      sendEmail,
      clearEmail,
      generateNewsletterEmailTemplate,
      generateShareEmailTemplate,
    };
  },
  { persist: true }
);

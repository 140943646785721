<template>
  <v-dialog v-model="isActive" width="auto" @click:outside="closeDialog">
    <v-card max-width="400" :text="props.message">
      <template v-slot:prepend>
        <v-icon color="primary" icon="mdi-check-bold" />
      </template>
      <template v-slot:title>
        <p class="text-primary">{{ props.title }}</p>
      </template>
      <template v-slot:actions>
        <v-btn class="ms-auto" text="Ok" @click="closeDialog"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";

const props = defineProps(["modelValue", "title", "message"]);
const emit = defineEmits(["close-dialog"]);
const isActive = ref(true);

const closeDialog = () => {
  isActive.value = false;
  emit("close-dialog");
};

watchEffect(() => {
  isActive.value = props.modelValue;
});
</script>

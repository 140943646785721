<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { useRoute } from "vue-router";
import { useCommonStore } from "./store/common";

export default {
  name: "App",

  created() {
    const commonStore = useCommonStore();
    const { setScreenSize } = commonStore;

    window.addEventListener("resize", () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });

    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  },

  data: () => ({
    theme: "PitchlyTheme",
    route: useRoute(),
  }),
};
</script>

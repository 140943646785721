<template>
  <v-form fast-fail @submit.prevent class="bg-background">
    <v-text-field
      v-model="firstName"
      label="First Name of Founder*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="lastName"
      label="Last Name of Founder*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="contactNumber"
      label="Mobile Number*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="contactEmail"
      label="Email Address"
      :rules="[rules.required, rules.email]"
      readonly
      hint="Please contact the admin to update your email address"
      persistent-hint
    />

    <v-btn
      variant="outlined"
      class="text-primary my-3"
      @click="openChangePasswordDialog = true"
      >Change Password</v-btn
    >
  </v-form>

  <ChangePasswordDialog
    v-model="openChangePasswordDialog"
    @close-dialog="openChangePasswordDialog = false"
    @change-password-status="changePasswordStatus"
  />
  <SuccessDialog
    v-model="openSuccessDialog"
    title="Password changed successfully!"
    message="Please log-in again using your new credentials."
    @close-dialog="closeSuccessDialog"
  />
  <ErrorDialog
    v-model="openErrorDialog"
    :title="errorTitle"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import { useCompanyStore } from "@/store/company";
import { usePitchStore } from "@/store/pitch";
import { useOnePagerStore } from "@/store/onePager";
import { useNewsletterStore } from "@/store/newsletter";
import { storeToRefs } from "pinia";
import { auth } from "@/firebase/init";
import { signOut } from "firebase/auth";
import ChangePasswordDialog from "@/components/Dialogs/ChangePasswordDialog.vue";
import SuccessDialog from "@/components/Dialogs/SuccessDialog.vue";
import ErrorDialog from "@/components/Dialogs/ErrorDialog.vue";

const router = useRouter();

const emit = defineEmits(["disableNextButton"]);

const commonStore = useCommonStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();
const pitchStore = usePitchStore();
const onePagerStore = useOnePagerStore();
const newslettersStore = useNewsletterStore();

const { setUserDetails } = userStore;

const { rules, emailPattern } = storeToRefs(commonStore);
const { currentUser } = storeToRefs(userStore);

const { clearUserDetails } = userStore;
const { clearCompany } = companyStore;
const { clearPitch } = pitchStore;
const { clearOnePager } = onePagerStore;
const { clearNewsletter, clearNewsletters } = newslettersStore;

let openChangePasswordDialog = ref(false);
let openSuccessDialog = ref(false);
let openErrorDialog = ref(false);
let errorTitle = ref("");
let errorMsg = ref("");
const firstName = ref("");
const lastName = ref("");
const contactNumber = ref("");
const contactEmail = ref("");

onMounted(() => {
  firstName.value = currentUser.value.firstName;
  lastName.value = currentUser.value.lastName;
  contactNumber.value = currentUser.value.contactNumber;
  contactEmail.value = currentUser.value.contactEmail;
});

const changePasswordStatus = (payload) => {
  const { status, message } = payload;

  if (status === "error") {
    errorTitle = "Error in Change Password!";
    errorMsg = message;
    openErrorDialog.value = true;
  } else if (status === "success") {
    openSuccessDialog.value = true;
  }
};
const closeSuccessDialog = async () => {
  openSuccessDialog.value = false;

  try {
    clearUserDetails();
    clearCompany();
    clearPitch();
    clearOnePager();
    clearNewsletter();
    clearNewsletters();
    await signOut(auth);
    router.push("/");
  } catch (e) {
    console.log(e);
  }
};
const closeErrorDialog = () => {
  openErrorDialog.value = false;
};

watchEffect(() => {
  if (
    firstName.value !== "" &&
    lastName.value !== "" &&
    contactNumber.value !== "" &&
    contactEmail.value !== "" &&
    emailPattern.value.test(contactEmail.value)
  ) {
    setUserDetails({
      firstName: firstName.value,
      lastName: lastName.value,
      contactNumber: contactNumber.value,
      contactEmail: contactEmail.value,
    });
  }

  emit(
    "disableNextButton",
    firstName.value === "" ||
      lastName.value === "" ||
      contactNumber.value === "" ||
      contactEmail.value === "" ||
      !emailPattern.value.test(contactEmail.value)
  );
});
</script>

<style scoped>
.v-form {
  width: 100%;
  margin-top: 3%;
}
</style>

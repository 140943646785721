<template>
  <v-dialog
    v-model="isActive"
    @click:outside="closeDialog"
    :class="isMobile.default ? 'mobile-width' : 'normal-width'"
  >
    <v-card>
      <v-card-title class="text-primary title body-size justify-content-center">
        One-Pager Template
      </v-card-title>
      <p class="text-grey px-3 title justify-content-center card-subtitle">
        Fields with * are readonly. Go to your account to update these values.
      </p>
      <v-card-text>
        <v-form fast-fail @submit.prevent class="bg-white">
          <v-row v-for="field in fields" :key="field.label" class="box">
            <p class="label">
              {{ field.label }} {{ field.isReadonly ? "*" : "" }}
              <br />
              <span class="label-subtitle" v-if="field.subtitle">{{
                field.subtitle
              }}</span>
            </p>
            <v-textarea
              v-if="field.type === 'textarea'"
              v-model="field.model"
              :required="true"
              hide-details
              variant="plain"
              :readonly="field.isReadonly"
              auto-grow
              rows="3"
            />
            <v-text-field
              v-else-if="field.label.includes('Offer')"
              v-model="field.model.name"
              variant="plain"
              :readonly="field.isReadonly"
            />
            <v-text-field
              v-else
              v-model="field.model"
              variant="plain"
              :readonly="field.isReadonly"
            />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text="Cancel" @click="closeDialog"></v-btn>

        <v-btn
          class="bg-primary primary-button"
          text="Submit as PDF"
          variant="flat"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { useOnePagerStore } from "@/store/onePager";
import { useCompanyStore } from "@/store/company";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const commonStore = useCommonStore();
const onePagerStore = useOnePagerStore();
const companyStore = useCompanyStore();

const { isMobile } = storeToRefs(commonStore);
const { onePager } = storeToRefs(onePagerStore);
const { company } = storeToRefs(companyStore);

const { setOnePager, prepopulate } = onePagerStore;
const router = useRouter();

let fields = ref(null);

const props = defineProps(["modelValue"]);
const emit = defineEmits(["close-dialog"]);
const isActive = ref(false);

onMounted(() => {
  console.log(onePager.value);
  const amount = company.value.amountRaising;
  prepopulate({
    name: company.value.companyName,
    description: company.value.description,
    stage: company.value.stage,
    raising: company.value.investmentTerm,
    offer: company.value.offer,
    amount: new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
    }).format(!isNaN(amount) && amount > 0 ? amount : 0),
  });
});

const closeDialog = () => {
  isActive.value = false;
  emit("close-dialog", false);
};

const submit = () => {
  setOnePager(fields.value);

  router.push("/download/one-pager");
  closeDialog();
};

watchEffect(() => {
  isActive.value = props.modelValue;
  fields.value = onePager.value;
});
</script>
<style scoped>
.v-dialog {
  flex-direction: column;
}
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 70%;
}
.v-card {
  height: 100vh;
  padding: 3%;
}
.card-subtitle {
  padding: 3%;
}
.v-row {
  width: 100%;
  padding: 0;
}
.v-form {
  height: 100%;
  width: 100%;
}
.box {
  border: 2px, solid, black;
  width: auto;
  height: fit-content;
  margin: 1%;
}
.label {
  font-weight: 600;
  width: 30%;
  height: auto;
  background-color: #b6b6b6;
  .label-subtitle {
    font-size: smaller;
  }
}
.v-text-field,
.v-textarea,
.v-select {
  width: 50%;
  padding-left: 2%;
}
</style>

<template>
  <v-stepper alt-labels v-model="currentStep" class="bg-background">
    <template v-slot>
      <v-stepper-header>
        <template v-for="(step, index) in steps" :key="`${step.title}-step`">
          <v-stepper-item
            :complete="currentStep > index + 1"
            :value="index + 1"
            :title="step.header || step.title"
            :color="currentStep > index + 1 ? 'primary' : ''"
          />
        </template>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item
          v-for="(step, index) in steps"
          :key="`${step.title}-content`"
          :value="index + 1"
        >
          <template v-slot>
            <p class="subheading title2-size text-primary">
              {{
                step.title + (step.isRequired ? " (required)" : " (optional)")
              }}
              <v-btn
                v-if="isMobile.pitch"
                density="compact"
                icon="mdi-information"
                variant="icon"
                color="secondary-blue"
                @click="openAideDialog = true"
              />
            </p>
            <component
              :is="step.content"
              @disable-next-button="disableNextButton"
            />
          </template>
        </v-stepper-window-item>
      </v-stepper-window>

      <v-row class="align-center justify-content-end footer">
        <v-btn variant="text" v-if="currentStep > 1" @click="prevStep">{{
          "Previous"
        }}</v-btn>
        <v-btn
          :disabled="disableNextStep"
          class="bg-primary"
          @click="nextStep"
          >{{ currentStep === steps.length ? "Preview" : "Next" }}</v-btn
        >
      </v-row>
    </template>
  </v-stepper>
  <ComponentDialog
    :component="AideSection"
    v-model="openAideDialog"
    @close-dialog="openAideDialog = false"
  />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useCommonStore } from "@/store/common";
import { usePitchStore } from "@/store/pitch";
import { useAideStore } from "@/store/aide";
import { storeToRefs } from "pinia";
import OnePager from "./PitchFields/OnePager.vue";
import ElevatorPitch from "./PitchFields/ElevatorPitch.vue";
import PitchDeck from "./PitchFields/PitchDeck.vue";
import AideSection from "../AideSection.vue";
import ComponentDialog from "../Dialogs/ComponentDialog.vue";

const router = useRouter();
const commonStore = useCommonStore();
const pitchStore = usePitchStore();
const aideStore = useAideStore();

const { isMobile } = storeToRefs(commonStore);
const { stepRedirect } = storeToRefs(pitchStore);

const { setAide } = aideStore;

let currentStep = ref(1);
const steps = [
  {
    title: "Pitch Deck",
    content: PitchDeck,
    isRequired: true,
    template: null,
  },
  {
    title: "Elevator Pitch",
    content: ElevatorPitch,
    isRequired: true,
    template: null,
  },
  {
    header: "One-Pager",
    title: "Your One-Pager",
    content: OnePager,
    isRequired: true,
    template: "",
  },
];
let disableNextStep = ref(false);
let openAideDialog = ref(false);

const prevStep = () => {
  if (currentStep.value >= 1) {
    currentStep.value--;
  }
};
const nextStep = () => {
  if (currentStep.value < steps.length) {
    currentStep.value++;
  } else if (currentStep.value === steps.length) {
    router.push("/preview");
  }
};
const disableNextButton = (value) => {
  disableNextStep.value = value;
};

watchEffect(() => {
  if (stepRedirect.value !== null) {
    currentStep.value = stepRedirect.value;
  }
  disableNextStep.value = currentStep.value > steps.length;
  setAide(currentStep.value - 1);
});
</script>

<style scoped>
.v-stepper {
  height: 90%;
  width: 90%;
}
.v-stepper-window {
  height: 63%;
  margin-bottom: 0;
  overflow-y: auto;
}
.v-btn {
  width: fit-content;
  margin-right: 3%;
}
.v-stepper-item {
  max-width: 100px;
}
.footer {
  margin-top: auto;
  margin-bottom: 1%;
}
</style>

import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useNewsletterStore = defineStore(
  "NewsletterStore",
  () => {
    const selectedNewsletter = ref(null);
    const newsletters = ref([]);

    const isEmpty = computed(
      () =>
        selectedNewsletter.value === null ||
        (selectedNewsletter.value?.title === "" &&
          selectedNewsletter.value?.body === "")
    );
    const newslettersList = computed(() =>
      newsletters.value.sort((a, b) => (b.date > a.date ? 1 : -1))
    );

    const setNewsletter = (value) => {
      selectedNewsletter.value = value;
    };

    const addNewsletter = (value) => {
      newsletters.value.push(value);
      console.log("storevalue", " => ", value);
    };

    const updateNewsletter = (index, value) => {
      newsletters.value[index] = value;
    };

    const removeNewsletter = (id) => {
      var index = newsletters.value.findIndex((x) => x.id == id);

      if (index >= 0) {
        newsletters.value.splice(index, 1);
      }
    };

    const clearNewsletter = () => {
      selectedNewsletter.value = null;
    };
    const clearNewsletters = () => {
      newsletters.value = [];
    };

    return {
      selectedNewsletter,
      newsletters,
      isEmpty,
      newslettersList,
      setNewsletter,
      clearNewsletter,
      clearNewsletters,
      addNewsletter,
      updateNewsletter,
      removeNewsletter,
    };
  },
  { persist: true }
);

<template>
  <v-row class="main bg-white" id="convert">
    <v-form class="bg-white">
      <p class="title">Startup One-Pager</p>

      <v-row v-for="field in onePager" :key="field.label" class="box">
        <p class="label">
          {{ field.label }}
          <br />
          <span class="label-subtitle" v-if="field.subtitle">{{
            field.subtitle
          }}</span>
        </p>
        <p
          class="value"
          v-if="
            field.model?.name?.length ||
            field.model?.length ||
            !isNaN(field.model)
          "
        >
          {{ field.label.includes("Offer") ? field.model.name : field.model }}
        </p>
      </v-row>
    </v-form>

    <!-- <v-row class="footer bg-secondary-background align-center">
      <v-col cols="8"
        ><i>Access this One-Pager Template at Pitchly.online</i></v-col
      >
      <v-spacer />
      <v-col cols="3" class="logo justify-content-end">
        <v-img
          :src="require('../assets/pitchly-logo-transparent.png')"
          alt="App Logo"
          max-width="40%"
          contain
      /></v-col>
    </v-row> -->
  </v-row>
  <LoadingDialog
    :modelValue="openLoadingDialog"
    message="Saving One-Pager..."
  />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useUserStore } from "@/store/user";
import { useOnePagerStore } from "@/store/onePager";
import { usePitchStore } from "@/store/pitch";
import { storeToRefs } from "pinia";
import {
  ref as storageRef,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase/init";
import { doc, setDoc } from "firebase/firestore";
import { useRouter } from "vue-router";
import html2pdf from "html2pdf.js";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";

const router = useRouter();

const userStore = useUserStore();
const onePagerStore = useOnePagerStore();
const pitchStore = usePitchStore();

const { currentUser } = storeToRefs(userStore);
const { onePager } = storeToRefs(onePagerStore);

const { setStepRedirect, setOnePagerFile } = pitchStore;

const storageFolder = "onepagers/"+currentUser.value.companyuid +"/";

let openLoadingDialog = ref(false);

onMounted(async () => {
  const element = document.getElementById("convert");
  var opt = {
    filename: "One-Pager.pdf",
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  try {
    openLoadingDialog.value = true;

    const blob = await html2pdf()
      .set(opt)
      .from(element)
      .outputPdf("blob", "One-Pager.pdf");

    console.log(blob);
    const filename = "One-Pager.pdf";
    const fbStorage = getStorage();
    const fbStorageRef = storageRef(fbStorage, storageFolder + filename);

    const snapshot = await uploadBytes(fbStorageRef, blob);
    console.log(snapshot);
    const downloadURL = await getDownloadURL(storageRef(fbStorageRef));

    console.log(downloadURL);

    await setDoc(doc(db, "onepagers", currentUser.value.uid), {
      downloadURL: downloadURL,
      filename: filename,
    });

    setOnePagerFile({
      filename: filename,
      url: downloadURL,
    });

    setStepRedirect(3);
    router.push("/pitch");
  } catch (e) {
    console.log(e);
  }
});
</script>

<style scoped>
.main {
  height: auto;
}
.v-row {
  width: 100%;
  padding: 0;
}
.v-form {
  height: fit-content;
  width: 100%;
  padding-top: 3%;
}
.title {
  font-weight: 800;
  margin-bottom: 3%;
  margin-left: 2%;
}
.box {
  border: 2px, solid, black;
  width: auto;
  height: fit-content;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-right: 2%;
}
.label {
  font-weight: 600;
  width: 30%;
  height: auto;
  background-color: #b6b6b6;
  .label-subtitle {
    font-size: smaller;
  }
}
.value {
  width: 65%;
  margin-left: 2%;
}
.footer {
  height: 5%;
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
  margin-top: auto;
}
</style>

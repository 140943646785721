<template>
  <v-dialog v-model="isActive" max-width="320" persistent>
    <v-list class="py-2" color="primary" elevation="12" rounded="lg">
      <v-list-item :title="props.message">
        <template v-slot:prepend>
          <v-progress-circular
            color="primary"
            indeterminate="disable-shrink"
            size="20"
            width="3"
            class="mr-2"
          ></v-progress-circular>
        </template>
      </v-list-item>
    </v-list>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, watchEffect } from "vue";

const props = defineProps(["modelValue", "message"]);
const isActive = ref(false);

watchEffect(() => {
  isActive.value = props.modelValue;
});
</script>

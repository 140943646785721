import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore(
  "UserStore",
  () => {
    const currentUser = ref({
      firstName: "",
      lastName: "",
      contactNumber: "",
      contactEmail: "",
      companies: [],
      password: "",
      uid: "",
      companyuid: "",
    });

    const setUserDetails = (payload) => {
      const {
        firstName,
        lastName,
        contactNumber,
        contactEmail,
        password,
        uid,
        companyuid,
      } = payload;
      currentUser.value.firstName = firstName;
      currentUser.value.lastName = lastName;
      currentUser.value.contactNumber = contactNumber;
      currentUser.value.contactEmail = contactEmail;
      currentUser.value.password = password;
      currentUser.value.uid = uid;
      currentUser.value.companyuid = companyuid;
    };
    const clearUserDetails = () =>
      (currentUser.value = {
        firstName: "",
        lastName: "",
        contactNumber: "",
        contactEmail: "",
        companies: [],
        password: "",
        uid: "",
        companyuid: "",
      });

    return {
      currentUser,
      setUserDetails,
      clearUserDetails,
    };
  },
  { persist: true }
);

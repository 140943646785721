import { defineStore } from "pinia";
import { ref } from "vue";

export const usePitchStore = defineStore(
  "PitchStore",
  () => {
    const pitch = ref({
      onePager: {
        filename: "",
        url: "",
      },
      elevator: {
        filename: "",
        url: "",
      },
      pitchDeck: {
        filename: "",
        url: "",
      },
    });
    const stepRedirect = ref(null);
    const currentPitch = ref("pitchDeck");

    const pitches = ref([
      {
        label: "Pitch Deck",
        key: "pitchDeck",
      },
      {
        label: "Elevator Pitch",
        key: "elevator",
      },
      {
        label: "One-Pager",
        key: "onePager",
      },
    ]);

    const setPitch = (value) => (pitch.value = value);
    const setStepRedirect = (value) => (stepRedirect.value = value);
    const setCurrentPitch = (value) => (currentPitch.value = value);
    const setOnePagerFile = (payload) => {
      const { filename, url } = payload;
      pitch.value.onePager.filename = filename;
      pitch.value.onePager.url = url;
    };
    const setElevatorFile = (payload) => {
      const { filename, url } = payload;
      pitch.value.elevator.filename = filename;
      pitch.value.elevator.url = url;
    };
    const setPitchDeckFile = (payload) => {
      const { filename, url } = payload;
      pitch.value.pitchDeck.filename = filename;
      pitch.value.pitchDeck.url = url;
    };

    const clearPitch = () =>
      (pitch.value = {
        onePager: {
          filename: "",
          url: "",
        },
        elevator: {
          filename: "",
          url: "",
        },
        pitchDeck: {
          filename: "",
          url: "",
        },
      });

    return {
      pitch,
      stepRedirect,
      currentPitch,
      pitches,
      setPitch,
      setStepRedirect,
      setCurrentPitch,
      setOnePagerFile,
      setElevatorFile,
      setPitchDeckFile,
      clearPitch,
    };
  },
  { persist: true }
);

<template>
  <v-row
    class="drop-zone justify-content-center align-center"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="getFiles"
    @change="getSelectedFiles"
    :class="{ 'active-dropzone': active }"
  >
    <p>Drag and drop PDF file</p>
    <p>or</p>
    <label for="onePagerFile">Upload PDF file</label>
    <p>or</p>
    <label for="useTemplate" class="bg-primary" @click="toggleDialog = true"
      >Use Pitchly's Template</label
    >
    <input type="file" id="onePagerFile" class="onePagerFile" accept=".pdf" />
    <p>PDF File: {{ filename }}</p>
  </v-row>

  <OnePagerDialog v-model="toggleDialog" @close-dialog="closePublishDialog" />

  <LoadingDialog
    :modelValue="openLoadingDialog"
    message="Saving One-Pager..."
  />

  <ErrorDialog
    v-model="openErrorDialog"
    :title="errorTitle"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />

  <SuccessDialog
    v-model="openSuccessDialog"
    title="Uploaded and saved!"
    message="One-pager has been successfully uploaded and saved."
    @close-dialog="openSuccessDialog = false"
  />
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { usePitchStore } from "@/store/pitch";
import {
  ref as storageRef,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase/init";
import { doc, setDoc } from "firebase/firestore";
import pdfvuer from "pdfvuer";
import LoadingDialog from "../../Dialogs/LoadingDialog.vue";
import OnePagerDialog from "../../Dialogs/OnePagerDialog.vue";
import ErrorDialog from "../../Dialogs/ErrorDialog.vue";
import SuccessDialog from "../../Dialogs/SuccessDialog.vue";

const emit = defineEmits(["disableNextButton"]);

const userStore = useUserStore();
const pitchStore = usePitchStore();

const { setOnePagerFile } = pitchStore;

const { currentUser } = storeToRefs(userStore);
const { pitch, stepRedirect } = storeToRefs(pitchStore);

const { setStepRedirect } = pitchStore;

let toggleDialog = ref(false);
let openLoadingDialog = ref(false);
let openSuccessDialog = ref(false);
let openErrorDialog = ref(false);
let errorTitle = ref("");
let errorMsg = ref("");

const active = ref(false);
const file = ref("");
const newFile = ref("");
const filename = ref("");
const pdfdata = ref(null);
const numPages = ref(0);
const maxPages = 1;

const storageFolder = "onepagers/" +currentUser.value.companyuid +"/";

const closePublishDialog = () => {
  toggleDialog.value = false;
};
const toggleActive = () => {
  active.value = !active.value;
};
const getFiles = async (event) => {
  newFile.value = event.dataTransfer.files[0];

  toggleActive();
  validateFile();
};
const getSelectedFiles = () => {
  newFile.value = document.querySelector(".onePagerFile").files[0];
  validateFile();
};
const validateFile = async () => {
  if (newFile.value.type !== "application/pdf") {
    errorTitle.value = "Wrong type of file!";
    errorMsg.value =
      "Oops! It looks like you uploaded the wrong type of file. Currently, One-Pager only accepts PDF files.";
    openErrorDialog.value = true;
  } else {
    pdfdata.value = pdfvuer.createLoadingTask(
      URL.createObjectURL(newFile.value)
    );

    await pdfdata.value.then((pdf) => {
      numPages.value = pdf.numPages;
    });

    if (numPages.value > maxPages) {
      errorTitle.value = "Too many pages!";
      errorMsg.value =
        "Oops! It seems your one-pager file is longer than a single page. Research indicates that investors prefer concise, single-page documents. Please ensure your information fits onto just one page to increase your chances of capturing investor interest.";
      openErrorDialog.value = true;
    } else {
      file.value = newFile.value;
      await getFirebaseData();
    }
  }
};

const getFirebaseData = async () => {
  openLoadingDialog.value = true;

  try {
    const fbStorage = getStorage();
    const fbStorageRef = storageRef(fbStorage, storageFolder + file.value.name);

//uploadBytes(fbStorageRef, file.value).then((snapshot) => {
//      console.log(snapshot);
//      console.log("Uploaded a blob or file!");
//    });
    const snapshot = await uploadBytes(fbStorageRef, file.value);
    console.log(snapshot);
    const downloadURL = await getDownloadURL(storageRef(fbStorageRef));

    await setDoc(doc(db, "onepagers", currentUser.value.uid), {
      downloadURL: downloadURL,
      filename: file.value.name,
    });
    setOnePagerFile({
      filename: file.value.name,
      url: downloadURL,
    });

    openSuccessDialog.value = true;
  } catch (e) {
    console.log(e);
  }

  openLoadingDialog.value = false;
};
const closeErrorDialog = () => {
  newFile.value = "";
  pdfdata.value = null;
  numPages.value = 0;
  openErrorDialog.value = false;
};

//lifecycle hooks
onMounted(() => {
  file.value = pitch.value.onePager;
});

watchEffect(() => {
  if (stepRedirect.value !== null) {
    setStepRedirect(null);
    openSuccessDialog.value = true;
  }

  filename.value =
    file.value !== ""
      ? file.value.name
        ? file.value.name
        : file.value.filename !== ""
        ? file.value.filename
        : "No PDF file uploaded"
      : "No PDF file uploaded";

  emit("disableNextButton", pitch.value.onePager.url === "");
});
</script>

<style scoped>
.drop-zone {
  margin-top: 3%;
  height: 43vh;
  width: 100%;
  flex-direction: column;
  border: 2px dashed #4c6fbf;

  label {
    padding: 1% 2%;
    background-color: #4c6fbf;
    color: white !important;
    margin: 2%;
  }

  input {
    display: none;
  }
}
.active-dropzone {
  color: white;
  border-color: white;
  background-color: #4c6fbf;

  label {
    background-color: white;
    color: #4c6fbf !important;
    margin: 2%;
  }
}
</style>

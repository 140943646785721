<template>
  <v-dialog
    v-model="isActive"
    :class="isMobile.default ? 'mobile-width' : 'normal-width'"
    @click:outside="isLoading ? '' : closeDialog"
  >
    <v-card>
      <v-card-title class="text-primary title body-size justify-content-center">
        Change your password
      </v-card-title>
      <v-card-text>
        <v-form fast-fail @submit.prevent>
          <v-text-field
            v-model="oldPassword"
            label="Old Password*"
            :rules="[rules.required, rules.password]"
            :append-inner-icon="oldPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="oldPasswordVisible ? 'text' : 'password'"
            @click:append-inner="oldPasswordVisible = !oldPasswordVisible"
          />
          <v-text-field
            v-model="password"
            label="New Password*"
            :rules="[rules.required, rules.password]"
            :append-inner-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="passwordVisible ? 'text' : 'password'"
            @click:append-inner="passwordVisible = !passwordVisible"
          />
          <v-text-field
            v-model="confirmPassword"
            label="Confirm Password*"
            :rules="[
              rules.required,
              (value) => value === password || 'Passwords must match.',
            ]"
            :append-inner-icon="
              confirmPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
            "
            :type="confirmPasswordVisible ? 'text' : 'password'"
            @click:append-inner="
              confirmPasswordVisible = !confirmPasswordVisible
            "
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text="Cancel" @click="closeDialog"></v-btn>

        <v-btn
          class="bg-primary primary-button"
          text="Change Password"
          variant="flat"
          :disabled="disableChangePasswordButton"
          :loading="isLoading"
          @click="changePassword"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import { updatePassword } from "firebase/auth";
import {
  auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "@/firebase/init";

const emit = defineEmits(["close-dialog", "change-password-status"]);
const commonStore = useCommonStore();

const { isMobile, rules } = storeToRefs(commonStore);

let isActive = ref(true);
let disableChangePasswordButton = ref(true);
let isLoading = ref(false);
const oldPasswordVisible = ref(false);
const passwordVisible = ref(false);
const confirmPasswordVisible = ref(false);
const oldPassword = ref("");
const password = ref("");
const confirmPassword = ref("");
const user = auth.currentUser;

const closeDialog = () => {
  oldPassword.value = "";
  password.value = "";
  confirmPassword.value = "";
  isActive.value = false;
  emit("close-dialog");
};

const changePassword = async () => {
  isLoading.value = true;

  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      oldPassword.value
    );
    await reauthenticateWithCredential(auth.currentUser, credential);
    //console.log(password.value, user);
    await updatePassword(user, password.value);

    emit("change-password-status", {
      status: "success",
    });
  } catch (e) {
    console.log(e);
    emit("change-password-status", {
      status: "error",
      message: e.message,
    });
  }

  isLoading.value = false;
  closeDialog();
};

watchEffect(() => {
  disableChangePasswordButton.value =
    // oldPassword.value === "" ||
    password.value === "" ||
    confirmPassword.value === "" ||
    // oldPassword.value.length < 6 ||
    password.value.length < 6 ||
    confirmPassword.value.length < 6 ||
    password.value !== confirmPassword.value;
});
</script>

<style scoped>
.v-dialog {
  height: 80%;
  flex-direction: column;
}
.v-card {
  padding: 30px;
}
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 50%;
}
</style>

<template>
  <v-card class="bg-background overflow-container">
    <v-card-item class="bg-background">
      <v-card-title class="text-primary"> Newsletters </v-card-title>
      <template v-slot:append>
        <v-btn
          v-if="isMobile.newsletter"
          density="compact"
          icon="mdi-note-plus"
          variant="icon"
          color="primary"
          @click="openFormDialog = true"
        />
      </template>
      <v-card-subtitle class="text-black">
        New newsletters are sent out every morning at 3AM.
      </v-card-subtitle>
    </v-card-item>

    <v-divider thickness="2px" opacity="40%" color="primary" class="mt-2" />

    <v-card-text class="overflow-content">
      <v-list class="bg-background">
        <v-list-item v-for="newsletter in newslettersList" :key="newsletter.id">
          <v-col cols="11">
            <v-list-item-title> {{ newsletter.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{
                date.format(newsletter.date, "fullDate")
              }}</v-list-item-subtitle
            >
          </v-col>
          <template v-slot:append>
            <v-chip
              :color="newsletter.isSent ? 'success' : 'error'"
              class="justify-content-center"
            >
              {{ newsletter.isSent ? "Sent" : "Pending" }}
            </v-chip>
            <v-menu>
              <template v-slot:activator="{ props: menu }">
                <v-btn
                  density="compact"
                  icon="mdi-dots-vertical"
                  size="large"
                  variant="icon"
                  color="primary"
                  v-bind="mergeProps(menu)"
                />
              </template>

              <v-list class="bg-background">
                <v-list-item
                  title="Duplicate"
                  @click="addToNewsletterQueue(newsletter)"
                />
                <v-list-item
                  v-if="!newsletter.isSent"
                  title="Edit"
                  @click="getNewsletter(newsletter)"
                />
                <v-list-item
                  v-if="!newsletter.isSent"
                  title="Delete"
                  @click="deleteNewsletter(newsletter)"
                />
              </v-list>
            </v-menu>
          </template>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>

  <ComponentDialog
    :component="NewsletterForm"
    v-model="openFormDialog"
    @close-dialog="openFormDialog = false"
  />

  <LoadingDialog
    :modelValue="openLoadingDialog"
    :message="`${loadingMessage}...`"
  />
</template>

<script setup>
import { ref, mergeProps } from "vue";
import { useDate } from "vuetify";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import { useNewsletterStore } from "@/store/newsletter";
import { storeToRefs } from "pinia";
import { db } from "@/firebase/init";
import { doc, deleteDoc } from "firebase/firestore";
import { addDoc, collection } from "firebase/firestore";
import NewsletterForm from "../components/Forms/NewsletterForm.vue";
import ComponentDialog from "../components/Dialogs/ComponentDialog.vue";
import LoadingDialog from "./Dialogs/LoadingDialog.vue";

const commonStore = useCommonStore();
const userStore = useUserStore();
const newsletterStore = useNewsletterStore();

const date = useDate();

const { isMobile } = storeToRefs(commonStore);
const { currentUser } = storeToRefs(userStore);
const { newslettersList } = storeToRefs(newsletterStore);

const { addNewsletter, setNewsletter, removeNewsletter } = newsletterStore;

let openFormDialog = ref(false);
let openLoadingDialog = ref(false);
let loadingMessage = ref("");

const addToNewsletterQueue = async (newsletter) => {
  loadingMessage.value = `Duplicating ${newsletter.title}`;
  openLoadingDialog.value = true;

  let newNewsletter = {
    date: new Date().toISOString(),
    title: newsletter.title,
    body: newsletter.body,
    isSent: false,
    companyID: currentUser.value.companyuid,
  };

  const newsletterRef = await addDoc(
    collection(db, "newsletters"),
    newNewsletter
  );

  console.log("Document written with ID: ", newsletterRef.id);

  newNewsletter.id = newsletterRef.id;

  addNewsletter(newNewsletter);
  openLoadingDialog.value = false;
};

const getNewsletter = (newsletter) => {
  setNewsletter(newsletter);
  if (isMobile.value.newsletter) {
    openFormDialog.value = true;
  }
};

const deleteNewsletter = async (newsletter) => {
  loadingMessage.value = `Deleting ${newsletter.title}`;
  openLoadingDialog.value = true;

  await deleteDoc(doc(db, "newsletters", newsletter.id));

  removeNewsletter(newsletter.id);

  openLoadingDialog.value = false;
};
</script>

<style scoped>
.v-col {
  padding: 0;
}
.v-card {
  height: 80%;
  width: 90%;
}
.v-card-item {
  height: 15%;
}
.v-card-subtitle {
  font-size: 13px;
  text-overflow: initial !important;
  white-space: initial !important;
}
.v-card-text {
  overflow-y: auto;
  height: 85%;
}
.v-list-item {
  padding: 2%;
  margin-bottom: 3%;
}
.v-list-item__content {
  width: 50px !important;
}
.v-chip {
  width: 90px;
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";

export const useOnePagerStore = defineStore(
  "OnePagerStore",
  () => {
    const stages = ref(["Ideation", "Validation", "Launch", "Growth"]);
    const raisings = ref([
      "Angel / Pre-seed",
      "Seed",
      "Series A",
      "Seeking Feedback",
    ]);
    const offers = ref([
      {
        name: "Share Purchase Agreement",
        definition:
          "An equity share price agreement is a formal contract detailing the terms for buying and selling shares, including price, quantity, payment terms, and transfer procedures, ensuring transparency and protecting both parties.",
      },
      {
        name: "Convertible Note",
        definition:
          "A convertible note is a short-term loan that converts into equity in a startup during a future funding round, often at a discounted rate, providing investors with potential equity upside.",
      },
      {
        name: "SAFE",
        definition:
          "A SAFE (Simple Agreement for Future Equity) is a contract that lets investors convert their investment into equity in a startup during a future funding round, simplifying the fundraising process without interest or maturity dates.",
      },
      {
        name: "Debt Financing",
        definition:
          "Debt financing is borrowing capital that must be repaid over time with interest, usually through loans from banks or financial institutions. It requires regular payments and can provide tax benefits through deductible interest.",
      },
      {
        name: "Revenue-based Financing",
        definition:
          "Revenue-based financing (RBF) allows businesses to receive capital in exchange for a percentage of future revenue, offering flexible repayments and avoiding equity dilution, making it suitable for growth-focused companies.",
      },
    ]);
    const onePager = ref([
      {
        label: "Name of Startup",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Startup Overview",
        subtitle: "(up to 90 words)",
        type: "textarea",
        model: null,
        isReadonly: true,
      },
      {
        label: "Startup Stage",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Fundraising Round",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Offer to Investor",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "No. of Employees",
        type: "textfield",
        model: null,
        isReadonly: false,
      },
      {
        label: "Market Size",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Amount Raising (PHP)",
        type: "textfield",
        model: null,
        isReadonly: true,
      },
      {
        label: "Use of Fund",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Challenge",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Solution",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Market",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Milestone",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Revenue Model",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Competitive Advantage",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Exit",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
      {
        label: "Founding Team",
        type: "textarea",
        model: null,
        isReadonly: false,
      },
    ]);

    const setOnePager = (payload) => {
      onePager.value = payload;
    };
    const prepopulate = (payload) => {
      const { name, description, stage, raising, offer, amount } = payload;
      console.log(payload);

      onePager.value[0].model = name;
      onePager.value[1].model = description;
      onePager.value[2].model = stage;
      onePager.value[3].model = raising;
      onePager.value[4].model = offer;
      onePager.value[7].model = amount;
    };
    const clearOnePager = () => {
      onePager.value.forEach((element) => (element.model = null));
    };

    return {
      stages,
      raisings,
      offers,
      onePager,
      setOnePager,
      prepopulate,
      clearOnePager,
    };
  },
  { persist: true }
);

<template>
  <v-card-text>
    <p>
      A highly effective pitch deck should consists of 10 to 15 slides. This
      concise format forces you to focus on the most critical elements of your
      business or idea, ensuring that each slide carries significant weight and
      contributes meaningfully to your pitch.
    </p>
    <v-card-subtitle> Click slides below to guide you </v-card-subtitle>
    <v-card-subtitle>
      or download the slides
      <a
        class="text-primary"
        href="https://docs.google.com/presentation/d/1Wm-F2LhGP4Zp7-2KjrLI8YsoyWCfe6balvnt5uhNn88/export/pptx"
        >here</a
      >
    </v-card-subtitle>
    <iframe
      src="https://docs.google.com/presentation/d/e/2PACX-1vQscfuCcoq2w3QfWZYWZ4HHEYUwRzE2Samx60w-Woju2OWbVUiXx3MNpisNb5z1pDcgB-1E2rOY-6-v/embed?start=false&loop=true&delayms=3000"
      frameborder="0"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    />
  </v-card-text>
</template>

<script setup></script>

<style scoped>
.v-card-text {
  height: 65vh;
}
p {
  margin-top: 8%;
  margin-bottom: 8%;
}
iframe {
  margin-top: 3%;
  width: 100%;
  height: 50%;
}
</style>

import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useCommonStore = defineStore("CommonStore", () => {
  const windowWidth = ref(window.innerWidth);
  const windowHeight = ref(window.innerHeight);
  const emailPattern = ref(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/);
  const rules = ref({
    required: (value) => (!!value && value.length > 0) || "Required Field.",
    email: (value) => emailPattern.value.test(value) || "Invalid e-mail.",
    password: (value) => value.length >= 6 || "Min of 6 characters.",
    positive: (value) => {
      const values = value?.split("₱");

      return (
        (values.length > 0 && values[0] !== "-" && Number(values[1]) !== 0) ||
        `"Must be a positive number"`
      );
    },
  });

  const isMobile = computed(() => ({
    default: windowWidth.value <= 750,
    newsletter: windowWidth.value <= 1010,
    pitch: windowWidth.value <= 1200,
  }));

  const setScreenSize = (payload) => {
    const { width, height } = payload;
    windowWidth.value = width;
    windowHeight.value = height;
  };

  return {
    windowWidth,
    windowHeight,
    emailPattern,
    rules,
    isMobile,
    setScreenSize,
  };
});

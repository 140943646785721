<template>
  <NavBar investorView="true" />
  <PitchContent />
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { db } from "@/firebase/init";
import { doc, getDoc } from "firebase/firestore";
import { usePitchStore } from "@/store/pitch";
import { useUserStore } from "@/store/user";
import { useCompanyStore } from "@/store/company";
import NavBar from "@/components/NavBar.vue";
import PitchContent from "@/components/PitchContent.vue";

const route = useRoute();
const pitchStore = usePitchStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();

const { setPitch } = pitchStore;
const { setCurrentPitch } = pitchStore;
const { setUserDetails } = userStore;
const { setCompanyDetails, setInvestmentDetails } = companyStore;

onMounted(async () => {
  const userId = route.params.userId;

  const docRef = doc(db, "user", userId);
  const docSnap = await getDoc(docRef);
  const user = docSnap.data();

  setUserDetails({
    firstName: user.firstName,
    lastName: user.lastName,
    contactNumber: user.mobileNumber,
    contactEmail: user.email,
    companyuid: user.company,
  });

  const docRefCompany = doc(db, "company", user.company);
  const docSnapCompany = await getDoc(docRefCompany);
  const company = docSnapCompany.data();

  setCompanyDetails({
    companyName: company.companyName,
    description: company.companyDescription,
    stage: company.stage,
    tags: company.tags,
  });

  setInvestmentDetails({
    investmentTerm: company.terms,
    offer: company.offer,
    amountRaising: company.amountRaising,
  });

  setCurrentPitch("pitchDeck");
  const docRefPitchDeck = doc(db, "pitchdecks", userId);
  const docSnapPitchdeck = await getDoc(docRefPitchDeck);

  const docRefEle = doc(db, "elevatorpitches", userId);
  const docSnapEle = await getDoc(docRefEle);

  const docRefOne = doc(db, "onepagers", userId);
  const docSnapOne = await getDoc(docRefOne);

  setPitch({
    onePager: {
      filename: docSnapOne.data.filename,
      url: docSnapOne.data().downloadURL,
    },
    elevator: {
      filename: docSnapEle.data.filename,
      url: docSnapEle.data().downloadURL,
    },
    pitchDeck: {
      filename: docSnapPitchdeck.data.filename,
      url: docSnapPitchdeck.data().downloadURL,
    },
  });
});
</script>
